<template>
  <section
    :class="`common-container wiki-commom-type-${domainConfig['designType']} detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar currentPage="detail" />
    <div class="common" :class="`common_${deviceType}`">
      <div class="wiki-banner" :style="{
         backgroundImage: `url(/background/type-${domainConfig['bg']}.png)`,
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat'
       }">
        <div class="wiki-banner-head">
          Privacy Policy
        </div>
      </div>

      <div class="flex-auto common-half-pc ">
        <div class="common-item">
          <div class="common-content">
            At <strong>{{ domainConfig['IAmURL'] }}</strong>, we are committed to protecting your
            privacy while offering a comprehensive resource for philosophy enthusiasts to discover
            the lives
            and ideas of the world’s greatest {{ domainConfig['keyword'] }}. This privacy policy
            outlines how we
            collect, use, and safeguard your personal information.
          </div>

          <div class="common-title">
            Information Collection
          </div>

          <div class="common-content">
            We may collect personal information, such as your name, email address, and any other
            information you provide when subscribing to our newsletter or contacting us.
          </div>

          <div class="common-title">
            Use of Information
          </div>

          <div class="common-content">
            The information we collect is used to enhance our services, respond to inquiries, and
            send newsletters or promotional materials. We will not sell or rent your personal
            information to third parties.
          </div>

          <div class="common-title">
            Third-Party Services
          </div>

          <div class="common-content">
            We may use third-party services to assist in operating our Website. These third parties
            may access your information solely for performing tasks on our behalf and are obligated
            not to disclose or use it for any other purposes.
          </div>

          <div class="common-title">
            Data Security
          </div>

          <div class="common-content">
            We implement reasonable security measures to protect your personal information from
            unauthorized access, alteration, or destruction. However, please note that no internet
            transmission method can guarantee 100% security.
          </div>

          <div class="common-title">
            Children&#39;s Privacy
          </div>

          <div class="common-content">
            Our Website is not intended for children under the age of 13. We do not knowingly
            collect personal information from children under 13.
          </div>

          <div class="common-title">
            Policy Changes
          </div>

          <div class="common-content">
            We may update this privacy policy periodically. We encourage you to review it regularly
            to stay informed on how we are protecting your information.
          </div>

          <div class="common-title">
            Contact Information
          </div>

          <div class="common-content">
            If you have any questions or concerns about this privacy policy or our privacy
            practices, please contact us at <strong>{{ domainConfig['IAMEMAIL'] }}</strong>.
          </div>

          <div class="common-content">
            By using <strong>{{ domainConfig['IAmURL'] }}</strong>, you agree to this policy,
            gaining access
            to an enriching platform for exploring the works and ideas of influential
            {{ domainConfig['IAmURL'] }}
            throughout history.
          </div>
        </div>
        <footer>
          Copyright © 2024 {{ domainConfig['IAmURL'] }}. All Rights Reserved.
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import HeadBar from '@/pages/components/head_pc.vue';

import '@/css/home.scss';

export default {
  name: 'Privacy',
  components: {
    HeadBar,
  },
  mixins: [common],
  computed: {
    ...mapGetters([
      "domainConfig"
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
